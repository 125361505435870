import { useState } from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { NavbarColor } from '../../lib/types';
import type { LocaleLocale } from '@eva/cms-types';
import useCookies from '../../hooks/react/useCookies';

interface Props {
  locale?: string;
  desktop?: boolean;
  color?: NavbarColor;
  availibleLocales: LocaleLocale[];
}

function LocaleButton(props: Props) {
  const [open, setOpen] = useState(false);
  const { setCookie } = useCookies();

  const setLocale = (locale: string) => {
    setCookie('locale', locale, 'max-age=31536000'); // one-year
    window.location.reload();
  };

  if (props.availibleLocales.length > 1) {
    return (
      <div className="relative">
        <CSSTransition in={open} timeout={200} classNames="fade" unmountOnExit>
          <div
            className={cx(
              'absolute z-40 bottom-[calc(100%+1rem)] bg-white rounded-xl text-body1 min-w-[12.5rem] flex flex-col left-1/2 -translate-x-1/2 py-2',
              props.desktop && 'top-[calc(100%+1.25rem)] !bottom-auto',
            )}
          >
            {props.availibleLocales.map(
              (locale: LocaleLocale['attributes'], index) => (
                <button
                  className="py-3"
                  onClick={() => setLocale(locale.code)}
                  key={index}
                >
                  {locale.label}
                </button>
              ),
            )}
          </div>
        </CSSTransition>
        <button
          onClick={() => setOpen(!open)}
          className={cx(
            'text-h4 whitespace-nowrap text-white inline-flex items-center gap-2 uppercase',
            props.desktop && '!text-body1 mr-5',
            props.color === NavbarColor.light && '!text-dark',
          )}
        >
          {props.locale ?? 'cs'}
          <img
            src="/chevron-down-white.svg"
            className={cx(
              open && 'rotate-180',
              'transition-all',
              props.color === NavbarColor.light && 'invert',
            )}
            alt=""
          />
        </button>
      </div>
    );
  }
  return null;
}

export default LocaleButton;
